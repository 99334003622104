@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.spot-form__field-group {
  &.in-cell {
    .spot-form__select {
      height: 30px;
    }

    .spot-form__select-input {
      font-size: 13px;
      padding-left: 4px;
      padding-right: 34px;
    }
  }

  .spot-form__select {
    .spot-form__select-input {
      .spot-form__select-option[disabled] {
        color: $spot-color-neutral-74;
      }
    }
  }
}
